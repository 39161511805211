<template>
  <div class="page" :style="{ backgroundImage: `url(${pageBgImg})` }">
    <div style="flex: 110"></div>
    <div class="flex-1 mainArea" style="flex: 451">
      <div class="top">
        <div class="flex m-h-15 m-t-5">
          <div class="part">
            <img class="part" :src="partImg" />
          </div>
          <div class="flex-1"></div>
        </div>
        <div class="flex flex-ct m-t-15" v-if="title">
          <div class="title p-h-8">{{ title }}</div>
        </div>
      </div>
      <div class="container flex-1">
        <div :class="['subtitle flex-ct m-t-10']" v-if="subtitle">
          {{ subtitle }}
        </div>
        <div :class="[' imgArea m-t-10']">
          <img
            class="img_w_100 img_h_100 img_cover mainImg"
            :src="mainImg"
            alt=""
            @click="gotoPage"
          />
        </div>
        <div class="more flex flex-ct" @click="gotoPage">
          <img class="img" src="@/images/more_arrow_right.png" alt="" />
          <div class="p-h-15">点击探索更多</div>

          <img class="img" src="@/images/more_arrow_left.png" alt="" />
        </div>
      </div>
      <div class="container flex-1">
        <div :class="['subtitle flex-ct m-t-10']">古树观赏方法</div>
        <div :class="[' imgArea m-t-10']">
          <img
            class="img_w_100 img_h_100 img_cover mainImg"
            src="@/images/微信图片_20240923162536.jpg"
            alt=""
            @click="gotoPage2('/ancient-tree-viewing-methods')"
          />
        </div>
        <div
          class="more flex flex-ct"
          @click="gotoPage2('/ancient-tree-viewing-methods')"
        >
          <img class="img" src="@/images/more_arrow_right.png" alt="" />
          <div class="p-h-15">点击探索更多</div>

          <img class="img" src="@/images/more_arrow_left.png" alt="" />
        </div>
      </div>
    </div>
    <div style="flex: 21"></div>
    <div class="footer">
      <div class="producer">
        <p>·奥迪中国与北京绿化基金会联合呈现·</p>
        <p>支持单位：国家林业草原古树健康与古树文化工程技术研究中心</p>
      </div>
    </div>
    <div style="flex: 21"></div>
  </div>
</template>

<script>
export default {
  props: {
    dotActiveIndex: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    routePath: {
      type: String,
      default: ""
    },
    pageBgImg: {
      type: String,
      default: ""
    },
    mainImg: {
      type: String,
      default: ""
    },
    clickNextPage: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    partImg() {
      return this.part3;
    }
  },
  data() {
    return {
      part3: require("@/images/part3.png")
    };
  },
  methods: {
    gotoPage() {
      if (this.routePath) {
        this.$router.push(this.routePath);
      }
    },
    gotoPage2(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mainArea {
  margin-left: 32px;
  margin-right: 32px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;
  background: rgba($color: #000000, $alpha: 0.7);
  border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
  .container {
    display: flex;
    flex-direction: column;
  }
  .top {
    .part {
      height: 10px;
    }
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: block;
      margin-left: 4px;
      &.active {
        background-color: #40c066;
      }
    }
  }
  .title {
    font-size: 26px;
    color: #ffffff;
    background-image: url("~@/images/page-top-yinhao1.png"),
      url("~@/images/page-top-yinhao2.png");
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: 7px auto, 7px auto;
  }
  .subtitle {
    font-size: 13px;
    color: #ffffff;
  }
  .line {
    width: 93px;
    height: 2px;
    background: #ffffff7e;
    .linedot {
      background: #ffffff;
      height: 2px;
      width: 32px;
    }
  }
  .imgArea {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;
    .mainImg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .more {
    height: 24px;
    font-size: 13px;
    color: #a3a3a3;
    background: #fff;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    .img {
      width: 35px;
    }
  }
}

.producer {
  text-align: center;
  font-size: 10px;
  line-height: 1.25em;
}
.arrowDown {
  padding-bottom: 22px;
  opacity: 0;
  .img {
    width: 28px;
  }
}
.footer {
  flex-shrink: 0;
}
// .arrowDown,
// .producer {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   bottom: 22px;
//   z-index: 10;
// }
</style>
