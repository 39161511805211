var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content"},[_c('div',{ref:"swiper",staticClass:"swiper swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((4),function(index){return _c('div',{key:index,staticClass:"swiper-slide"},[(index === 1)?_c('div',{staticClass:"page1"},[_vm._m(0,true)]):(index === 2)?_c('Page',{attrs:{"dotActiveIndex":1,"title":"北京城市代表性古树","subtitle":"古树之王的故事","routePath":"/beijing-city-representative-ancient-tree","pageBgImg":_vm.page2bg,"mainImg":_vm.page2MainImg,"clickNextPage":_vm.clickNextPage}}):(index === 3)?_c('Page',{attrs:{"dotActiveIndex":2,"title":"北京古树游览推荐","subtitle":"古树观赏地图","routePath":"/beijing-ancient-tree-map","pageBgImg":_vm.page3bg,"mainImg":_vm.page3MainImg,"clickNextPage":_vm.clickNextPage}}):(index === 4)?_c('Page4',{attrs:{"dotActiveIndex":3,"title":"古树知识小课堂","subtitle":"什么是古树","routePath":"/ancient-tree-knowledge-class?id=what_is_an_ancient_tree","pageBgImg":_vm.page4bg,"mainImg":_vm.page4MainImg,"clickNextPage":_vm.clickNextPage}}):_vm._e()],1)}),0)])]),_c('nav',{staticClass:"navs"},[_c('span',{class:{
        nav: true,
        active: _vm.activeIndex === 0
      },on:{"click":function($event){return _vm.gotoHome(0)}}},[_vm._v("指南首页")]),_c('span',{class:{
        nav: true,
        active: _vm.activeIndex === 1
      },on:{"click":function($event){return _vm.gotoHome(1)}}},[_vm._v("代表古树")]),_c('span',{class:{
        nav: true,
        active: _vm.activeIndex === 2
      },on:{"click":function($event){return _vm.gotoHome(2)}}},[_vm._v("游览推荐")]),_c('span',{class:{
        nav: true,
        active: _vm.activeIndex === 3
      },on:{"click":function($event){return _vm.gotoHome(3)}}},[_vm._v("知识课堂")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showProducer),expression:"!showProducer"}],staticClass:"arrowDown",on:{"click":_vm.clickNextPage}},[_c('img',{staticClass:"img",attrs:{"src":require("@/images/arrow_down.png"),"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textArea m-r-18 flex flex-cl"},[_c('div',{staticClass:"t1"},[_vm._v("2024年")]),_c('div',{staticClass:"t2"},[_vm._v("北京自然城市")]),_c('div',{staticClass:"t2"},[_vm._v("古树观赏指南")]),_c('div',{staticClass:"t4 m-t-10"},[_vm._v("奥迪中国")]),_c('div',{staticClass:"t4"},[_vm._v("北京绿化基金会")]),_c('div',{staticClass:"t4"},[_vm._v("支持单位:")]),_c('div',{staticClass:"t4"},[_vm._v(" 国家林业草原古树健康与古树文化工程技术研究中心 ")])])
}]

export { render, staticRenderFns }