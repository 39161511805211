<template>
  <div class="page">
    <HomeM v-if="isMobile" />
    <HomeP v-else />
  </div>
</template>

<script>
import HomeM from './Home_m.vue'
import HomeP from './Home_p.vue'
import { mapState } from 'vuex';
export default {
  components: {
    HomeM,
    HomeP
  },
  computed: {
    ...mapState(['isMobile'])
  },
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
}
</style>